import {
  AdminPanelSettingsOutlined,
  ApartmentOutlined,
  ChairOutlined,
  CottageOutlined,
  Dashboard,
  FactCheckOutlined,
  FactCheckTwoTone,
  FactoryOutlined,
  GrassOutlined,
  GroupsOutlined,
  HandshakeOutlined,
  LocalAtmOutlined,
  ManageAccountsOutlined,
  ReceiptLongOutlined,
} from "@mui/icons-material";
import {
  Rectangle_54_1,
  Rectangle_54_2,
  Rectangle_54_3,
  Rectangle_55_1,
  Rectangle_55_2,
  Rectangle_56_1,
  Rectangle_56_2,
  Rectangle_56_3,
  bulid_2,
} from "../img";

export const services = [
  {
    title: "product_box.cards.card_3.title",
    images: {
      img_1: Rectangle_54_1,
      img_2: Rectangle_54_2,
      img_3: Rectangle_54_3
    },
    disc: "product_box.disc.disc_1",
    path: "/real_estate_units"
  },
  {
    title: "product_box.cards.card_2.title",
    images: {
      img_1: Rectangle_55_1,
      img_2: Rectangle_55_2,  
      img_3: bulid_2
    },
    disc: "product_box.disc.disc_2",
    path: "/partners"
  },
  {
    title: "product_box.cards.card_1.title",
    images: {
      img_1: Rectangle_56_1,
      img_2: Rectangle_56_2,
      img_3: Rectangle_56_3
    },
    disc: "product_box.disc.disc_3",
    path: "/payment"
  }
];
export const searchBox = [
  {
    title: "product_page.search_box.menu.items.item_1",
    value: 1,
    icon: <ApartmentOutlined />
  },
  {
    title: "product_page.search_box.menu.items.item_2",
    value: 2,
    icon: <ChairOutlined />
  },
  {
    title: "product_page.search_box.menu.items.item_3",
    value: 3,
    icon: <CottageOutlined />
  },
  {
    title: "product_page.search_box.menu.items.item_4",
    value: 4,
    icon: <GrassOutlined />
  }
];
export const sortList = [
  // {title: "product_page.search_box.by_date", value: 1},
  {title: "product_page.search_box.by_price", id: "price"}
];
export const mastrListAdmin = [
  {
    title: "dashboard.Home.List.dashboard",
    path: "/dashboard/info",
    icon: <Dashboard />,
    value:"1"
  },
  {
    title: "dashboard.Home.List.orders",
    path: "/dashboard/orders",
    icon: <FactCheckOutlined />,
    value:"2" 
  },
  {
    title: "dashboard.Home.List.transfer-orders",
    path: "/dashboard/transfer-orders",
    icon: <FactoryOutlined />,
    value:"2" 
  },
  {
    title: "dashboard.Home.List.seller",
    path: "/dashboard/sales_representative",
    icon: <GroupsOutlined />,
    value:"3" 
  },
  {
    title: "dashboard.Home.List.bank_seller",
    path: "/dashboard/sales_representative/bank_",
    icon: <LocalAtmOutlined  />,
    value:"3" 
  },
  {
    title: "dashboard.Home.List.developers",
    path: "/dashboard/developers",
    icon: <ManageAccountsOutlined />,
    value:"4"

  },
  {
    title: "dashboard.Home.List.partners",
    path: "/dashboard/partners",
    icon: <HandshakeOutlined />,
    value:"5"

  },
  {
    title: "dashboard.Home.List.suopervies",
    path: "/dashboard/suopervies",
    icon: <AdminPanelSettingsOutlined />,
    value:"6"

  },
  {
    title: "dashboard.Home.List.reportes",
    path: "/dashboard/reports",
    icon: <ReceiptLongOutlined />,
    value:"7"

  }
  // {
  //   title: "dashboard.Home.List.setting",
  //   path: "",
  //   icon: <SettingsSuggestOutlined />
  // }
];
export const salesRepresentative = {
  heder: [
    "#",
   "All_tables.name",
   "All_tables.id",
   "All_tables.totlePrice",
   "All_tables.suports",
   "All_tables.documents",
   "All_tables.notes",
   "All_tables.stute",
  ],
  userInfo: [
    "All_tables.requestable_name",
    "All_tables.phone",
    "All_tables.brathday",
    "All_tables.job",
    "All_tables.rank",
    "All_tables.start_job",
    "All_tables.salary",
  ],
};

export const aboutListMessage = [
  {
    title: "about.message"
  },
  {
    title: "about.vision"
  },
  {
    title: "about.gools"
  }
];
export const servise_Buttom = [
  {title: "servise_Buttom.selers", path: ""},
  {title: "servise_Buttom.developers", path: ""},
  {title: "servise_Buttom.orders", path: ""},
  {title: "servise_Buttom.partners", path: ""}
];
