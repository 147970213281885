import {Box, Container, Grid, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import InfoBox from "../components/admin/InfoBox";
import {useTranslation} from "react-i18next";
import UserTableInfo from "../components/admin/UserTableInfo";
import {Store} from "../context/DataStore";
import {cancel_orders, checked_1, clock_1, customer_review} from "../img";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import TransferOrdersTable from "../components/admin/TransferOrdersTable";

export default function SalesBankRepresentative() {
  const {i18n} = useTranslation();
  const pageval = 2;
  const {userInfo, options} = Store(); 
  const [statistics, setStatistics] = useState("");
  const navigate = useNavigate()

  useEffect(() => {
     
  }, []);
  
  const ordersInfo = [
    {
      img: customer_review,
      title: "dashboard.orders.info.new_orders",
      number: statistics?.total || 0,
      label: "dashboard.orders.info.Lable"
    },
    {
      img: checked_1,
      title: "dashboard.orders.info.accept_orders",
      number: statistics?.accepted || 0,
      label: "dashboard.orders.info.Lable"
    },
    {
      img: cancel_orders,
      title: "dashboard.orders.info.cancel_orders",
      number: statistics?.refused || 0,
      label: "dashboard.orders.info.Lable"
    },
    {
      img: clock_1,
      title: "dashboard.orders.info.hold_orders",
      number: statistics?.pending || 0,
      label: "dashboard.orders.info.Lable"
    }
  ];
  const getStatistics = async () => {
    await axios
      .get("requests/statistics", {headers: options})
      .then((res) => {
        if (res.status === 200) {
          setStatistics(res.data.data);
          console.log(res.data.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem("userToken");
          localStorage.removeItem("userInfo");
          navigate("/");
          return;
        }

      });
  };
  useEffect(() => {
    getStatistics();
  }, []);
  return (
    <Box px={1} py={3}>
      <Container>
        <Grid container spacing={1}>
          {ordersInfo.map((item, index) => (
            <Grid item key={index} xs={12} md={3}>
              <InfoBox ele={item} />
            </Grid>
          ))}
        </Grid>
        <Box mt={5} p={5}>
          <Typography
            component={"h2"}
            color={"#000"}
            fontFamily={"Tajawal-b"}
            variant="h5"
            fontWeight={900}
          >
            {i18n.t("dashboard.orders.title")}
          </Typography>
        </Box>
        <Box>
          <TransferOrdersTable statistics={getStatistics} />
        </Box>
      </Container>
    </Box>
  );
}
