import {createBrowserRouter} from "react-router-dom";
import ClientLayout from "../layouts/ClientLayout";
import Home from "../pages/Home";
import RealEstate from "../pages/RealEstate";
import AdminLayout from "../layouts/AdminLayout";
import Dashboard from "../pages/Dashboard";
import Orders from "../pages/Orders";
import DashboardInfo from "../pages/DashboardInfo";
import SalesRepresentative from "../pages/SalesRepresentative";
import Partners from "../pages/Partners";
import Developers from "../pages/Developers";
import Reports from "../pages/Reports";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import About from "../pages/About";
import Contactus from "../pages/Contactus";
import PaymentSol from "../pages/PaymentSol";
import PartnerShip from "../pages/PartnerShip";
import Suopervies from "../pages/Suopervies";
import RealEstateView from "../pages/RealEstateView";
import TransferOrders from "../pages/TransferOrders";

export const Router = createBrowserRouter([
  {
    path: "/",
    element: <ClientLayout />,
    children: [
      {
        index: true,
        element: <Home />
      },
      {
        path: "/real_estate_units",
        element: <RealEstate />
      },
      {
        path: "/real_estate_units/:id",
        element: <RealEstateView />
      },
      {
        path: "/about",
        element: <About />
      },
      {
        path: "/partners",
        element: <Partners />
      },
      {
        path: "/privacy_policy",
        element: <PrivacyPolicy />
      },
      {
        path: "/contactus",
        element: <Contactus />
      },
      {
        path: "/payment",
        element: <PaymentSol />
      }
    ]
  },
  {
    path: "/dashboard",

    element: (
        <AdminLayout />
    ),
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
        children: [
          {path: "/dashboard/info", element: <DashboardInfo />},
          {
            path: "/dashboard/orders",
            element: <Orders />
          },
          {
            path: "/dashboard/transfer-orders",
            element: <TransferOrders />
          },
          {
            path: "/dashboard/sales_representative/:type?",
            element: <SalesRepresentative />
          },
          {
            path: "/dashboard/developers",
            element: <Developers />
          },
          {
            path: "/dashboard/reports",
            element: <Reports />
          },
          {
            path: "/dashboard/partners",
            element: <PartnerShip />
          },
          {
            path: "/dashboard/suopervies",
            element: <Suopervies />
          }
        ]
      }
    ]
  }
]);
