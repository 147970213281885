import { Camera } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import { Store } from "../context/DataStore";
import axios from 'axios';
import { enqueueSnackbar } from 'notistack'; 

const UserImageCircle = ({ initialImage, setRefresh = null }) => {
  const [image, setImage] = useState(initialImage);
  const { files, setFiles, options, userInfo } = Store();

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
  
      // Create a new FormData instance
      const formData = new FormData();
      // Append the file to the FormData instance
      formData.append('file', file);
  
      // Make the API call
      await axios.post(
        `/common/profile/image/update`,
        formData,
        { headers: options }
      ).then((response) => {
        enqueueSnackbar(`${response.data.message}`, { variant: "success" }); 
        console.log(response.data.data);

        let UpdatedUserInfo = {...response.data.data, token: userInfo.token}
        localStorage.setItem('userInfo', JSON.stringify(UpdatedUserInfo));
        if(setRefresh) setRefresh(response.data.data.image);
        
      }).catch((error) => {
        enqueueSnackbar(`${error.response.data.message}`, { variant: "error" }); 
      });
    }
  };
  

  const handleClick = () => {
    document.getElementById('fileInput').click();
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <IconButton 
        style={{position: "absolute", zIndex: "1001", top: "150px !important", left: "150px !important"}}
        sx={{bgcolor: "#ffffffb3", ":hover": {bgcolor: "#6fa4b8"}}}
        onClick={handleClick}
      >
        <Camera />
      </IconButton>
      <img
        src={image}
        alt="User Profile"
        style={{
          width: '100px', // Adjust width as needed
          height: '100px', // Adjust height as needed
          borderRadius: '50%',
          objectFit: 'cover',
          cursor: 'pointer',
        }}
        onClick={handleClick}
      />
      <input
        type="file"
        id="fileInput"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={handleImageChange}
      />
    </div>
  );
};

export default UserImageCircle;
