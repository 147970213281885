import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {salesRepresentative} from "../../data/Lists";
import {Close, NoteAltOutlined, SearchOutlined} from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  InputBase,
  Pagination,
  Stack,
  TextField
} from "@mui/material";
import {useTranslation} from "react-i18next";
import OredrStateAdmin from "./OredrStateAdmin";
import AddNewClient from "./AddNewClient";
import axios from "axios";
import {Store} from "../../context/DataStore";
import {useSnackbar} from "notistack";
import Reportes from "./Reportes";
import { useParams } from "react-router-dom";

export const EditNotes = ({id, notes}) => {
  const {i18n} = useTranslation();
  const [note, setNote] = useState(notes ? notes : "");
  const [open, setOpen] = useState(false);
  const {options, userInfo} = Store();

  const {enqueueSnackbar} = useSnackbar();
  const openHandler = () => {
    setOpen(true);
  };
  const addNotes = async () => {
    await axios
      .put(
        "requests/update/note",
        {request_id: id, notes: note},
        {headers: options}
      )
      .then((res) => {
        if (res.status === 200) {
          enqueueSnackbar(`${res.data.message}`, {variant: "success"});
          setOpen(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {note ? (
        <>
          {" "}
          <Typography bgcolor={"#fff"} color={"#000"} width={50} noWrap>
            {note}{" "}
          </Typography>
          {note !== "" && (
            <Typography
              color={"#09c"}
              sx={{cursor: "pointer"}}
              component={"a"}
              onClick={() => {
                setOpen(true);
              }}
            >
              {i18n.t("g.more")}
            </Typography>
          )}
        </>
      ) : (
        <IconButton onClick={openHandler}>
          <NoteAltOutlined />
        </IconButton>
      )}
      <Dialog open={open} fullWidth>
        <Box p={2}>
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <Typography
          textAlign={"center"}
          fontFamily={"Tajawal-b"}
          fontWeight={700}
          variant="h6"
        >
          {i18n.t("All_tables.add_notes")}
        </Typography>
        <DialogContent>
          {note === "" ? (
            <Typography textAlign={'center'} variant="h6" fontWeight={800}>{i18n.t("AddNewClient.no_notes")}</Typography>
          ) : (
            <Typography component={"p"} textAlign={"start"}>
              {note}
            </Typography>
          )}
          {userInfo.role === "admin" && (
            <Box>
              <TextField
                fullWidth
                defaultValue={note}
                onChange={(e) => {
                  setNote(e.target.value);
                }}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          {userInfo.role === "admin" && (
            <Button onClick={addNotes} variant="contained">
              {i18n.t("AddNewClient.add")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

function Row(props) {
  const {i18n} = useTranslation();
  const {row} = props;
  const [open, setOpen] = useState(false);
  const {userInfo} = Store();

  return (
    <React.Fragment>
      <TableRow sx={{"& > *": {borderBottom: "none !important"}}}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center">{row.id}</TableCell>
        <TableCell align="center">{row.name}</TableCell>
        <TableCell align="center">{row.identity_number}</TableCell>
        <TableCell align="center">
          <Typography color={"#E42346"}>{row.total_commitment}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography color={!row.support ? "#E42346" : "green"}>
            {row.support
              ? i18n.t("All_tables.suport_by")
              : i18n.t("All_tables.unsport")}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Reportes data={row.file} />
        </TableCell>
        <TableCell align="center">
          <EditNotes id={row.id} notes={row.notes} />
        </TableCell>
        <TableCell align="center">
          {userInfo.role === "sales" ? (
            <Typography>{row.status?.title} </Typography>
          ) : (
            <OredrStateAdmin
              row={row}
              statistics={props.statistics}
              url={"requests/change-status"}
              fun={props.fun}
            />
          )}
        </TableCell>
      </TableRow>
      <TableRow sx={{"& > *": {borderBottom: "none"}}}>
        <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{margin: 1}}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow sx={{"& > *": {borderBottom: "none"}}}>
                    {salesRepresentative.userInfo.map((ele, index) => (
                      <TableCell align="center" key={index}>
                        {i18n.t(ele)}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow sx={{"& > *": {borderBottom: "none"}}}>
                    <TableCell align="center">{row.requestable_name}</TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.mobile}
                    </TableCell>
                    <TableCell align="center">{row.born_in}</TableCell>
                    <TableCell align="center">{row.job}</TableCell>
                    <TableCell align="center">{row.rank} </TableCell>
                    <TableCell align="center">{row.date_join} </TableCell>
                    <TableCell align="center">{row.salary} </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable({statistics,  is_company_form=false }) {
  const { type } = useParams();
  const [resSearch, setResSearch] = useState(null);
  const [data, setData] = useState();
  const { options, userInfo } = Store();
  const {i18n} = useTranslation();

  const getData = async () => {
    await axios
      .get("requests/all", {headers: options})
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const changeHandel = async (e, p) => {
    await axios
      .get(`requests/all?page=${p}`, {headers: options})
      .then((res) => {
        setData(res.data);
      });
  };
  const getSearch = async (key) => {
    await axios
      .get(`/requests/search/${key}`, {headers: options})
      .then((res) => {
        // console.log(res.data)
        setResSearch(res.data);
      })
      .catch((err) => {
        console.log(err);
        setResSearch("");
      });
  };

  useEffect(() => {
    if (resSearch === "" || null || undefined) {
      getData();
    }
  }, [resSearch]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Box
        width={"100%"}
        bgcolor={"#D9E0E2"}
        display={"flex"}
        justifyContent={"space-evenly"}
        alignItems={"center"}
        py={1}
        my={3}
      >
        <Box
          borderRadius={2}
          width={"50%"}
          p={0.5}
          bgcolor={"#fff"}
          display={"flex"}
          alignItems={"center"}
          gap={2}
        >
          <SearchOutlined sx={{color: "GrayText"}} />
          <InputBase
            fullWidth
            placeholder={i18n.t("search.titles.id_order")}
            onChange={(e) => {
              getSearch(e.target.value);
            }}
          />
        </Box>
        {userInfo.role !== "admin" && (
        <AddNewClient
          title={"AddNewClient.addOrder"}
          url={"/sales/request/create"}
          fun={getData}
          statistics={statistics}
          is_company_form={is_company_form}
        />
          
        )}
      </Box>

      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow sx={{"& > *": {borderBottom: "none"}}}>
              <TableCell />
              {salesRepresentative.heder.map((ele, index) => (
                <TableCell align="center" key={index}>
                  
                  {i18n.t(ele)}{" "}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(resSearch || data?.data)?.data?.map((row, index) => (
              <Row
                key={index}
                statistics={statistics}
                row={row}
                index={index}
                fun={getData}
              />
            ))}
          </TableBody>
        </Table>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          py={2}
        >
          <Stack spacing={2} sx={{direction: "ltr"}}>
            <Pagination
              count={data?.data?.meta?.last_page}
              variant="outlined"
              shape="rounded"
              page={data?.meta?.current_page}
              onChange={changeHandel}
            />
          </Stack>
        </Box>
      </TableContainer>
    </>
  );
}
