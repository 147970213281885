import {Close, ZoomOutMap} from "@mui/icons-material";
import {Box, Dialog, IconButton, Typography} from "@mui/material";
import React, {useState} from "react";
import {SwiperSlide, Swiper} from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

import "../../css/swiper.css";

import {Navigation} from "swiper/modules";
import {useTranslation} from "react-i18next";

export default function ImagesSwiper({data, id = 0}) {
  const {i18n} = useTranslation();

  const [open, setOpen] = useState(false);
  return (
    <Box>
      <IconButton 
        onClick={() => {
          setOpen(true);
        }}
        sx={{bgcolor: "#ffffffb3", ":hover": {bgcolor: "#6fa4b8"}}}
      >
        <ZoomOutMap />
      </IconButton>
      <Dialog open={open} fullWidth maxWidth={"md"}>
        <Box p={3}>
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <Box p={4}>
          {data?.length === 0 ? (
            <Typography 
              variant="h6"
              fontFamily={"Tajawal-b"}
              textAlign={"center"}
            >
              {i18n.t("g.pic_daillog")}
            </Typography>
          ) : (
            <Swiper
              navigation={true}
              modules={[Navigation]}
              className="mySwiper"
            >
              {data.map((img, index) => (
                <SwiperSlide  key={index}>
                  <img
                    src={img.replace(id<70? 'api.digitalline.sa' : 'api.digitalline.sa', 'api.digitalline.sa')}
                    alt={`cart-${index}`}
                    style={{height: "100%", width: "100%"}}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </Box>
      </Dialog>
    </Box>
  );
}
